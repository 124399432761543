/* eslint-disable */
export const NO = {
    ENTITIES: {
        SupplierInvoice: 'Leverandørfaktura',
        JournalEntryLine: 'Bilagslinje',
        Budget: 'Budsjett',
        Supplier: 'Leverandør',
        Currency: 'Valuta',
        CustomerInvoice: 'Faktura',
        CustomerOrder: 'Ordre',
        CustomerQuote: 'Tilbud',
        CustomerInvoiceReminder: 'Purring',
        RecurringInvoice: 'Repeterende faktura',
        Customer: 'Kunder',
        Product: 'Produkter',
        ProductCatagory: 'Produktgruppe',
        Sellers: 'Selgere',
        PayrollRun: 'Lønnsavregning',
        PayrollRun_plural: 'Lønnsavregninger',
        PayrollRun_plural_spesific: 'Lønnsavregningene',
        Employee: 'Ansatte',
        WageType: 'Lønnsart',
        Worker: 'Personer',
        WorkType: 'Timeart',
        WorkProfile: 'Stillingsmal',
        Project: 'Prosjekt',
    },

    SALES: {
        INVOICE: {
            INVOICE: 'Faktura',
            NEW: 'Ny faktura',
            INVOICED: 'Fakturert',
            INVOICE_DATE: 'Fakturadato',
            CREDIT_DAYS: 'Kredittdager',
            INVOICE_REF: 'Fakturaref.',
            PAYMENT_INFO_TEXT:
                'Betalingen fra kunde vil bli registrert i systemet. Husk at betalingen fra kunde må ha kommet inn i banken før du registrerer betalingen.',
        },
        RECURRING_INVOICE: {
            RECURRING_INVOICE_NEW: 'Ny repeterende faktura',
            RECURRING_INVOICE_NUMBER: 'Repeterende fakturanr. {nr}',
            LOG_HEADER: 'Fakturalogg for repeterende fakturanr. {nr}',
            GOTO: 'Gå til repeterende fakturanr. {nr}',
            MISSING_DIM_TOAST_TITLE: 'Repeterende faktura(er) mangler dimensjon(er)',
            DELETE: 'Slette repeterende faktura?',
            DELETE_CONFIRM: 'Er du sikker på at du vil slette denne repeterende faktura? Dette kan ikke angres.',
            DELETED_NR: 'Repeterende fakturanr. {id} er fjernet.',
        },
        COMMON: {
            DELVIERY_DATE: 'Leveringsdato',
            OUR_REF: 'Vår referanse',
            YOUR_REF: 'Deres referanse',
            COMMENT: 'Kommentar',
            REQUISITION: 'Rekvisisjon',
            MAIN_SELLER: 'Hovedselger',
            PRINT: 'Utskrift',
            SENDING_LAST: 'Siste utsendelse',
            SENDING_STATUS: 'Status utsendelse',
        },
        CUSTOMER: {
            DETAILS: 'Kundedetaljer',
            DETAILS_INFO:
                'Her ligger generel informasjon om kunden. Organisasjonsnummer brukes for selskap og fødselsnummer for privatpersoner. Legger du inn webadresse til kunden kan du gå til nettstedet ved å trykke på ikonet til høyre.',
            CONTACT_INFO:
                'Her finner du kontaktinformasjon til kunden. Du kan ha flere adresser, men den som velges vil foreslås ved bruk. Dersom leveringsadresse er utfyllt, vil denne komme med på tilbud, ordre og faktura',
            CONTACT_INFO_REMINDER:
                'Hvis kunden skal motta purringer på en annen e-post enn det som ligger her, legges denne e-postadressen inn i purre-epostadresse over. Kontakpersoner med tilhørende informasjon kan legger inn lengre nede på siden.',
            TERMS: 'Betingelser',
            TERMS_INFO:
                'Velg betalings- og leveringsbetingelser for kunde som avviker fra standard. Dersom kunden skal følge klientens standardinnstillinger, trenger du ikke legge inn noe her.',
            REMINDER: 'Purring',
            REMINDER_INFO_P1:
                'Her settes innstillinger for purringer. Dersom du ønsker å stoppe alle purringer på kunden, kan du huke av “Purrestopp”.',
            REMINDER_INFO_P2:
                'Hvis du har satt opp renteberegning i innstillinger for innkreving kan du her velge om kunden skal renteberegnes med purringen.',
            FACTORING_INFO:
                'Hvis du ikke ønsker at faktura for denne kunden skal sendes via Factoring, kan du velge dette vekk her.',
            DIMENSIONS_INFO:
                'Her kan du velge prosjekt, avdeling og egendefinerte dimensjoner som skal følge kunde. Om valgt, vil de settes automatisk ved opprettelse av tilbud, ordre og faktura.',
            MAIN_SELLER_MOVED_INFO: 'Hovedselger kan nå settes i selgertabellen lenger nede i bildet.',
            BANK: 'Bank & EHF',
            BANK_INFO:
                'Sett opp bankkontoer tilhørende kunde. KID-identifikator lar deg overstyre det systemgenererte KID-nummeret. Fyll kun ut verdi i dette feltet dersom du ønsker at kundenummer-del av KID skal erstattes av dette nummeret.',
            BANK_INFO_PEPPOL:
                'Peppoladresse identifiserer mottakere, og registreres på formatet <landkode>:<identifikator>. Kun dersom EHF skal sendes til utenlandsk mottaker skal du fylle inn verdi i dette feltet.',
            REPORTS: 'Blanketter',
            REPORTS_INFO:
                'Velg standardblanketter for tilbud, ordre og faktura. Disse vil bli valgt som standard ved utsendelse og forhåndsvisning',
            INVOICE_DEALS: 'Avtaler faktura',
            INVOICE_DEALS_INFO:
                'Husk at utsendelsesplanen for faktura (enten på Innstillinger eller evt denne kunden) må settes opp med AvtaleGiro som prioritet 1 og alternativ utsendelse som prioritet 2. Huk av for varsel om det skal sendes epost til kunde ved avtalegiro. Kun repeterende fakturaer vil sendes som AvtaleGiro.',
            INVOICE_DEALS_BUTTON: 'Detaljer viser historikk for kundens av- og påmeldinger for avtalegiro',
            CONTACTS: 'Kontakter',
            CONTACTS_INFO: 'Legg til kontakter på kunde.',
        },
        PRODUCT: {
            DETAILS: 'Produktdetaljer',
            DETAILS_INFO:
                'Legg inn detaljene for produktet ditt. Ved opprettelse av nytt produkt kan du enten fylle inn produktnummer eller la stå blankt for å la systemet velge neste ledige.',
            DETAILS_INFO_OPTIONAL: 'Feltene Produkttype, Enhet og Produktbeskrivelse er valgfrie.',
            PRICE: 'Pris',
            PRICE_INFO:
                'Velg inn- og utpris på produkt. Huk av boksen om du ønsker at oppgitt salgspris inkluderer moms.',
            ACCOUNT_SETTINGS: 'Regnskapsinnstillinger',
            ACCOUNT_SETTINGS_DETAILS:
                'Sett opp standard mva-kode og hovedbokskonto som skal settes automatisk på linjen når produkt velges i tilbud, ordre og faktura.',
            ACCOUNT_SETTINGS_DETAILS_PRODUCT_ACCOUNT:
                'Produktkontoer reflekterer "Standard salgskonto per mvakode" som er satt opp i innstillinger for salg. Du kan under endre konto for dette produktet per mvakode.',
            DIMENSIONS_INFO:
                'Her kan du velge prosjekt, avdeling og egendefinerte dimensjoner som skal følge produktet. Om valgt, vil de settes automatisk på linjen når produkt velges i tilbud, ordre og faktura.',
        },
        SELLER: {
            DETAILS: 'Selgerdetaljer',
            DETAILS_INFO:
                'Velg om selger skal kobles til bruker, ansatt og team. Ved å huke av for standard vår referanse, vil denne selgerens navn automatisk fylles inn i vår referanse på tilbud, ordre og faktura.',
            DIMENSIONS_INFO: 'Her kan du velge prosjekt, avdeling og egendefinerte dimensjoner på selger.',
        },
        PROJECT: {
            DETAILS: 'Prosjektdetaljer',
            DETAILS_INFO:
                'Fyll inn detaljer om prosjektet. Om prosjektnummer-feltet står tomt, får prosjektet tildelt nummer fra nummerserie. Når prosjektet er tatt i bruk på andre entiteter, kan prosjektnummer ikke endres.',
            LOCATION: 'Lokasjon',
            LOCATION_INFO: 'Om ønskelig kan prosjektlokasjon fylles ut her.',
            DATES: 'Prosjektdatoer',
            DATES_INFO:
                'Datoer relatert til prosjektet. Sett forventede start- og sluttdatoer, og faktiske datoer for oppstart og avslutning. Opprettet-feltet viser når prosjektet bli laget i systemet, og kan ikke endres.',
            BUDGET: 'Budsjett',
            BUDGET_INFO:
                'Legg inn kjente verdier for prosjektet. Løpende resultet og utgifter kan sees i real-time på dashboardet for prosjektet.',
        },
    },

    ACCOUNTING: {
        JOURNALENTRY: {
            JOURNAL_ENTRY: 'Bilagsføring',
            JOURNAL_ENTRY_NUMBER: 'Bilagsnr',
            DEBET: 'Debit',
            SUM_DEBET: 'Sum debit',
            CREDIT: 'Kredit',
            SUM_CREDIT: 'Sum kredit',
            JOURNALING_TYPE: 'Bilagstype',
        },
        SUPPLIER_INVOICE: {
            REFUND_INFO:
                'Tilbakebetalingen vil bli registrert som betalt i systemet. Husk å betale regningen i nettbanken dersom dette ikke allerede er gjort.',
            NEW: 'Ny leverandørfaktura',
            NUMBER: 'Leverandørfakturanr. {nr}',
            REGISTER_NEW: 'Registrer leverandørfaktura',
            REGISTER_RECEIPT: 'Registrer kvittering',
            REGISTER_REFUND: 'Registrer tilbakebetaling',
            REGISTER_JOURNALENTRY: 'Registrer bilag',
            SINGLE: 'Leverandørfaktura',
            SMART_BOOKING_ERROR_MSG:
                'Kan ikke kjøre smart bokføring. Leverandørfaktura mangler enten fakturabeløp eller leverandør med orgnr.',
            CREATE: 'Opprett leverandørfaktura',
            LATEST: 'Siste leverandørfaktura',
            BOOK_WITH_AMOUNT: 'Bokføre leverandørfaktura med beløp {sum}?',
            BOOK_WITH_SUPPLIER_NAME: 'Bokfør leverandørfaktura fra {name}',
            BOOK_AND_APPROVE_WITH_SUPPLIER_NAME: 'Godkjenne og bokfør leverandørfaktura fra {name}',
            BOOK_TO_PAYMENT_WITH_SUPPLIER_NAME: 'Bokfør og til betaling av leverandørfaktura fra {name}',
            APPROVE_BOOK_TO_PAYMENT_WITH_SUPPLIER_NAME:
                'Godkjenne, bokføre og til betaling av leverandørfaktura fra {name}',
            FILE_IN_USE_MSG:
                'Filen er allerede brukt i bilagsføring eller på en leverandørfaktura og kan derfor ikke slettes. Ønsker du å markere som brukt slik at den forsvinner fra innboks?',
            MULTIPLE_USE_MSG1: 'Flere leverandørfaktura knyttet til filen, viser siste',
            MULTIPLE_USE_MSG2:
                'Filen du vil bruke er knyttet til en leverandørfaktura. Henter fakturaen nå. Om dette ikke stemmer kan du slette filen fra leverandørfakturaen og gå tilbake til innboksen og starte på nytt med riktig fil.',
            SMART_BOOKING_2: 'Kontoforslag basert på bokføringer gjort på denne leverandøren i systemet',
            SMART_BOOKING_3:
                'Kontoforslag basert på bokføringer gjort på leverandører i samme bransje som valgt leverandør på din faktura.',
            NO_OCR_ACTIVATED: 'Fakturatolkning er ikke aktivert',
            PLEASE_ACTIVATE_OCR:
                'Vennligst aktiver fakturatolkning under Firmainnstillinger - Regnskap om du ønsker å benytte tolkning av fakturaer',
        },
        POSTPOST: {
            ALL_WITH_OPEN: 'Alle med åpne poster',
            ALL_WITH_OPEN_OVERDUE: 'Alle med åpne poster, forfalt',
            TITLE: 'Åpne poster {entity}',
            EXPORT: 'Eksport åpne poster',
            EXPORT_ALL: 'Eksport alle åpne poster',
        },
        EXPENSE: {
            TITLE: 'Tilbakebetaling',
        },
        INBOX: {
            COMBINE: 'Slå sammen til ett dokument',
            COMBINE_TO_INVOICE: 'Registrer som én leverandørfaktura',
            COMBINE_TO_RECIEPT: 'Registrer som én kvittering',
            COMBINE_TO_REFUND: 'Registrer som én tilbakebetaling',
            COMBINE_TO_JOURNALENTRY: 'Registrer som ett bilag',
            DELETE: 'Slett dokument',
            DELETE_MULTIPLE: 'Slett dokumenter',
            EDIT_DOCUMENT: 'Rediger tekst',
        },
        SUPPLIER: {
            DETAILS: 'Leverandørdetaljer',
            DETAILS_INFO:
                'Her ligger generel informasjon om leverandøren. Det kan ligge flere bankkontoer på leverandøren, den som vises foreslås som standard i systemet. Legger du inn webadresse til leverandøren kan du gå til nettstedet ved å trykke på ikonet til høyre.',
            CONTACT_INFO:
                'Her finner du kontaktinformasjon til leverandøren. Du kan ha flere adresser, men den du velger vil foreslås ved bruk. I e-post og telefon kan du legge inn flere telefonnumre. Kontaktpersoner med tilhørende informasjon kan legges inn lenger ned på siden.',
            TERMS_INFO: 'Her kan du legge inn dine betingelser hos leverandøren.',
            EHF_AND_EXTRA: 'EHF og innrapportering selvstendig næringsdrivende',
            EXTRA_INFO:
                'Peppoladresse identifiserer mottakere, og registreres på formatet <landkode>:<identifikator>. Kun dersom EHF skal sendes til utenlandsk mottaker skal du fylle inn verdi i dette feltet.',
            DIMENSIONS_INFO:
                'Her kan du velge prosjekt, avdeling og egendefinerte dimensjoner som skal følge leverandør. Om valgt, vil de settes automatisk ved opprettelse av leverandørfaktura.',
            STANDARD_COST_ACCOUNT:
                'Du kan velge mellom å sette opp standard kostnadskonto eller benytte fordelingsnøkkel.',
            COST_ALLOCATION:
                'Hvis du setter opp fordelingsnøkkel vil systemet automatisk foreslå splitting av kontering mellom f.eks. avdelinger på denne leverandøren.',
            CONTACTS: 'Kontakter',
            CONTACTS_INFO: 'Legg til kontakter på leverandør.',
        },
    },

    BANK: {
        NEEDS_ACTION_TOOLTIP:
            'Utbetalingsposter som kommer i denne listen, skyldes at banken har returnert kvitteringer på betalinger som ikke er laget i systemet. Vi har derfor ikke funnet en sammenfallende post i vårt system, og disse må behandles manuelt. Klikk på de 3 prikkene til høyre i listen for å se behandlingsmuligheter. Du kan unngå at poster kommer i denne listen ved å aktivere «Bokfør kun betalinger fra UE i Autobank-innstillingene. Da vil disse ikke bli bokført heretter.',
    },

    DISTRIBUTION: {
        PRINT: 'Skriv ut',
        INVOICE_PRINT: 'Fakturaprint fra tredjepart',
    },

    SALARY: {
        PAYROLL: {
            NEW: 'Ny lønnsavregning',
            NUMBER: 'Lønnsavregning {nr}',
            LATEST: 'Siste lønnsavregninger',
            TO_PAYMENT_HEADER: 'Lønn utbetaling',
            TO_PAYMENT_INFO_HEADER: 'Hvordan ønsker du å betale ut lønnen?',
            TO_PAYMENT_INFO: 'Til utbetaling',
        },

        TAX_CARD: {
            SHOW_MORE: 'Flere felt',
            SHOW_LESS: 'Skjul felter',
            SHOW_MORE_TEXT: 'Pensjon, Trygd utenlandsk borger, Grensegjenger, Uføreytelser fra andre',
            LAYOUT: {
                MAIN_LAYOUT_TITLE: 'Skattekort',
                SIMPLE_MAIN_LAYOUT: {
                    DESCRIPTIONS: {
                        TEXT1: 'Skattekort bør hentes direkte fra skatteetaten via altinn, men hvis ikke det er mulig så kan skattekort tastes inn manuelt her:',
                        TEXT2: 'Alle skattekort som hentes digitalt eller tastes inn blir automatisk merket som Hovedarbeidsgiver.  Er den ansatte ikke fast ansatt må det vurderes om den ansatte skal skattes som Biarbeidsgiver.  Det er mulig å endre skattekort fra hoved- til biarbeidsgiver underveis i året, og hvis du er usikker bør du velge Biarbeidsgiver.  Fyll ut Prosent og eller Tabell, det er lønnsarten som styrer hva som brukes hvis begge deler er utfylt.  Blir det ikke lagt inn skattekort vil systemet automatisk trekke 50% skatt.  Sist oppdatert viser datoen for siste endring.  Tilleggsopplysning er informasjon som kommer med det digitale skattekortet, og kan ikke endres av bruker.  Opplysninger her kan påvirke den ansattes skatteberegning.',
                        TEXT3: 'Har den ansatte frikort med eller uten beløpsgrense vises dette her. Har en ansatt frikort trekkes det ikke tabell eller prosent.  Er det frikort med beløpsgrense og beløpet er oversteget, trekkes 50% skatt.',
                        TEXT4: 'Antall måneder for trekk hentes fra skattekortet, og dette er med på å bestemme hvilke måneder den ansatte skal trekkes skatt for.  Mulige valg er 10,5 (som er den mest vanlige), 11 og 12.  11 her betyr full skatt alle måneder bortsett fra desember og 12 betyr at det skal trekkes ordinær skatt hele året.  Systemet bruker opplysninger fra disse feltene for å beregne hvem som skal ha halv skatt i desember.',
                        TEXT5: 'Status på skattekort kommer som en opplysning fra skatteetaten.  Får en noe annet enn "Skattekort OK" betyr det at Skatteetaten har gitt en tilbakemelding som må sjekkes opp av arbeidsgiver før du bruker skattekortet.',
                    },
                    LAST_UPDATED_LABEL: 'Sist oppdatert',
                    SUPPLEMENT_LABEL: 'Tilleggsopplysning status',
                },
                PENSION_LAYOUT: {
                    TITLE: 'Pensjonsskattekort',
                    DESCRIPTION: 'Brukes av pensjonsselskap og andre selskap som utbetaler pensjon.',
                },
                FOREIGN_CITIZEN_LAYOUT: {
                    TITLE: 'Trygd utenlandsk borger',
                    DESCRIPTION:
                        'Benyttes til lønn til bl.a. flyvende personell, landtransport, utenlandske lottfiskere. Den ansatte regnes ikke som skattemessig bosatt i Norge, og betaler kun trygdeavgift. Skatt beregnes etter dette skattekortet når Tilleggsopplysning på lønnsposten inneholder Skattemessig Bosatt Land, og den ansatte ikke har skattekort for grensegjenger.',
                },
                BORDER_CROSSING_CITIZEN_LAYOUT: {
                    TITLE: 'Grensegjenger',
                    DESCRIPTION:
                        'Med grensegjenger menes i NSA (den nordiske skatteavtalen) en person som er skattepliktig bosatt i en kommune som direkte grenser til riksgrensen mellom Norge og Sverige eller riksgrensen mellom Norge og Finland, og som utfører lønnet arbeid i en kommune som grenser direkte til den samme riksgrensen i det annet land. Skatt beregnes etter dette skattekortet når Tilleggsopplysning på lønnsposten inneholder Skattemessig Bosatt Land, og det fylles ut med FI (Finland) eller SE (Sverige). Har ikke den ansatte skattekort av type grensegjenger, så antas det at den ansatte skal skatte etter generelle regler for Ansatt som ikke regnes som skattemessig bosatt i Norge, men skal betale trygdeavgift, når denne tilleggsopplysningen er utfylt.',
                },
                DISABILITY_BENEFIT_LAYOUT: {
                    TITLE: 'Uføreytelser fra andre',
                    DESCRIPTION: 'Brukes av pensjonsselskap og andre selskap som utbetaler uføreytelser',
                },
                FULL_MAIN_LAYOUT: {
                    RESULT_STATUS: {
                        LABEL: 'Status på skattekort',
                        NOT_TAX_CARD: 'Ikke skattekort',
                        ASSESS_EMPLOYMENT: 'Vurder arbeidstillatelse',
                        NO_DRAW: 'Ikke trekkplikt',
                        TAX_OK: 'Skattekort OK',
                        INVALID_ORG_NR: 'Ugyldig orgnr',
                        INVALID_SSN_OR_DNR: 'Ugyldig f-nr/d-nr',
                        EXPIRED_DNR_SSN_RECEIVED: 'Utgått d-nr, skattekort for f-nr er levert',
                    },
                },
                MAIN_EMPLOYER: {
                    MAIN_EMPLOYER_LABEL: 'Hovedarbeidsgiver',
                    NOT_MAIN_EMPLOYER_LABEL: 'Biarbeidsgiver',
                },
                TABLE_AND_PERCENT: {
                    TABLE_LABEL: 'Tabell',
                    PERCENT_LABEL: 'Prosent',
                },
                FREE_AMOUNT: {
                    TYPE: {
                        LABEL: 'Frikorttype',
                        TYPE_NONE: 'Ingen',
                        TYPE_WITH_AMOUNT: 'Frikort med beløp',
                        TYPE_NO_LIMIT: 'Frikort uten beløp',
                    },
                    AMOUNT_LABEL: 'Frikortbeløp',
                },
                MONTH_LABEL: 'Antall måneder trekk',
            },
        },
        ABSENCE: {
            SELF_REPORTED: 'Egenmeldt fravær',
            SELF_REPORTED_SETTINGS_INFO:
                'Her velger du verdiene som gjelder for deres ansatte i forhold til egenmeldt fravær. Disse verdiene vil brukes til å validere registrering av fravær og gi indikator på når grenseverdier nærmes.',
            VACATION: 'Feriedager',
            VACATION_INFO:
                'Velg standard feriedager for ansatte i bedriften. De ansatte som har passert 60 år vil automatisk få 5 dager ekstra feriedager',
        },

        TAX_CARD_MODAL: {
            HEADER: 'Administrer elektronisk skattekort',
        },

        TAX_CARD_REQUEST: {
            TITLE_GOOD: 'Skatteforespørsel er sendt',
            TITLE_BAD: 'Feil angående Altinn-forespørsel',
            HEADER: 'Send forespørsel om skattekort',
            INFO: 'Her kan du sende forespørsel om skattekort til altinn. Din forespørsel vil bli behandlet i altinn, dette kan ta inntil ett døgn',
            EMPLOYEE_SELECTION_HEADER: 'Utvalg skattekortforespørsel:',
            RADIO_NO_END_DATE: 'Alle aktive ansatte (uten sluttdato per i dag)',
            RADIO_ALL_EMPLOYEES: 'Alle ansatte',
            RADIO_NO_TAX: 'Aktive ansatte uten skatteopplysninger i {year}',
            RADIO_EMPLOYEES_IN_CATEGORY: 'Ansatte i kategori',
            RADIO_ONLY_CHANGED: 'Hent kun endrede skattekort',
            EMPLOYEE_INFO: 'Ansatt nr.{number} - {name}',
            CHANGED_EMPS_LABEL: 'Ta med endrede skattekort',
            EMP_COUNT_MESSAGE: 'Det er med {count} ansatte i dette utvalget',
            CHANGED_EMPS_INFO:
                'Er det andre ansatte som har endret skattekort siden sist innlesing, vil disse bli hentet samtidig.',
            SEND_REQUEST_LABEL: 'Send forespørsel til Altinn',
            KEEP_SECONDARY_EMPLOYER_INFO: 'Kryss for Skattekort biarbeidsgiver beholdes i nytt år.',
            DONT_KEEP_SECONDARY_EMPLOYER_INFO: 'Kryss for Skattekort biarbeidsgiver beholdes ikke for nytt år.',
            KEEP_SECONDARY_EMPLOYER_INFO_SETUP: 'Oppsettet kan endres i lønnsinnstillinger.',
        },

        READ_TAX_CARD: {
            HEADER: 'Hent skattekort',
            INFO: 'Trykk på “Hent og les inn” -knappen for å lese inn skatteopplysinger fra Altinn. Der vil du få opp påloggingsbildet for personlig pålogging til Altinn.',
            CLOSE: 'Lukk',
            TIME_STAMP_COL: 'Dato sendt',
            ID_COL: 'ID',
            SIGNATURE_COL: 'Signatur',
            HAS_BEEN_REGISTERED_COL: 'Innlest',
        },

        PENSION_SCHEME: {
            MODAL_HEADER: 'Pensjonsinnretning',
            MODAL_MESSAGE: 'Registrer dine pensjonsinnretninger slik at de blir innrapportert korrekt i a-meldingen',
            NO_DATA: 'Ikke valgt',
            SEARCH_PLACEHOLDER: 'Pensjonsinnretning',
            NEW_CUSTOM_PENSION_SCHEME: '+ Legg til egen pensjonsinnretning',
        },
        CUSTOM_PENSION_SCHEME: {
            HEADER: 'Legg til egen pensjonsinnretning',
            MESSAGE:
                'Dersom du ikke finner din pensjonsinnretning under listen over pensjonsinnretninger kan du legge til din egen her',
            IDENTIFICATOR: 'Organisasjonsnummer',
            NAME: 'Navn',
        },
        AMELDING: {
            SUMMARY: {
                PENSION_SCHEME_TOAST_HEADER: 'Endring lagret',
                PENSION_SCHEME_TOAST_MESSAGE:
                    'Pensjonsinnretning er lagret. Du må generere ny a-melding og sende inn for at pensjonsinnretningen skal komme inn til A-ordningen.',
                ONLEAVE_VALIDATION_HEADER: 'Ansatte i permisjon - Husk å sjekke disse',
                ONLEAVE_EMPLOYEES: 'Ansattnr. {empNr}: {leaveStart} - {leaveEnd} - {leaveDesc}',
            },
            PENSION_SCHEME: {
                EDIT: 'Rediger',
                ADD: 'Legg til',
            },
        },
        WAGE_TYPE: {
            EMPTY_STATE: {
                HEADER: 'Du har ingen lønnsarter registrert',
                MESSAGE:
                    'Du har ikke lønnsarter registrert på firmaet for året du står på. Synkroniser lønnsarter for å kopiere lønnsarter fra fjoråret inn til dette året.',
                SYNC_LABEL: 'Synkroniser',
            },
        },
        EMPLOYMENT_HISTORY_MODAL: {
            HEADER: 'Historikk',
            CHANGED_AT_LABEL: 'Endret',
            STEP_LABEL: 'Lønnstrinn',
            WORK_PERCENT_LABEL: 'Prosent',
            HOUR_RATE_LABEL: 'Timesats',
            MONTH_RATE_LABEL: 'Månedssats',
            YEAR_RATE_LABEL: 'Årssats',
        },
    },

    TIMETRACKING: {
        PERSON_NEW: 'Ny person',
        HOURTOTALS: 'Timerapport',
        WORKTYPE_INFO:
            'Her ligger informasjon som navn og nummer for timearten. Ved opprettelse vil nummer settes til neste ledige automatisk dersom det ikke er satt.',
        WORKTYPE_INFO_DESCRIPTION: 'I beskrivelse kan du legge til interne notater for hva timearten brukes til',
        PRODUCT: 'Produkt',
        PRODUCT_INFO:
            'Her søker du opp hvilket produkt og pris du ønsker at timearten skal føres som på ordre og faktura',
        HOURS_TO_SALARY: 'Time til lønn',
        HOURS_TO_SALARY_INFO: 'Her legger du inn hvilken lønnsart disse skal hente inn som lønn ved utbetaling',
        ROUNDING_MINTUE: 'Opprunding av timer',
        ROUNDING_MINTUES_INFO:
            'Systemet kan automatisk runde opp fakturagrunnlaget ditt til nærmeste kvarter, halvtime eller time.',
        ROUNDING_MINUTES_AFFECT: 'Dette vil kun påvirke fakturasum og ikke arbeidstid.',
    },

    TICKER: {
        MAIN_LEDGER: 'Hovedbok',
    },

    TASKS: {
        TOOLBAR_HEADER: 'Dine oppgaver og godkjenninger',
    },

    STATUSES: {
        INVOICE: {
            PAST_DUE: 'Forfalt',
            REMINDED: 'Purret',
            SENT_TO_COLLECTOR: 'Sendt til inkasso',
            PAID: 'Betalt',
            CREDITED: 'Kreditert',
        },
        COMMON: {
            ALL: 'Alle',
            MINE: 'Mine',
            DRAFT: 'Kladd',
        },
        PAYROLL_RUN: {
            CREATED: 'Opprettet',
            CALCULATED: 'Avregnet',
            BOOKED: 'Bokført',
        },
    },

    SETTINGS: {
        REPORT_SETTINGS: 'Rapportoppsett',
        GENERAL: 'GENERELLE INNSTILLINGER',
        TOOLS: 'VERKTØY',
        ADMIN: 'ADMIN',
        BANK: 'Bankinnstillinger',
        BANK_ACCOUNTS: 'Bankkontoer',
        AUTO_BANK: 'Autobank',
        BANK_RULES: 'Bokføringsregler',
        COMPANY: 'Firma',
        CURRENCY: 'Valuta',
        OPENING_BALANCE: 'Inngående balanse',
        BOOK_FROM_SYSTEM: 'Bokfør kun utbetalinger fra systemet',
        AUTO_BANK_INFOTEXT:
            'Huk av "Bokfør kun utbetalinger fra systemet" om du ønsker at utbetalinger som ikke er sendt fra systemet (som ikke har EndToEndID) ikke blir bokført',
        WRITEOFF_INVOICE: 'Avansert tapsføring av fordringer',

        COMPANY_EMAIL: 'E-postmottak',
        FROM_BRREG: 'Hent opplysninger fra br-reg',
        ACCOUNTING: 'Regnskapsinnstillinger',
        REM_ACCOUNTS: 'Mellomkontoer',
        SALES: 'Salgsinnstillinger',
        SALES_INVOICE: 'Salgsfaktura',
        PERIOD_AND_MVA: 'Periode og MVA',
        ACCOUNTS: 'Kontoer',
        PAYMENT_TERMS: 'Betalingsbetingelser',
        DELIVERY_TERMS: 'Leveringsbetingelser',
        KID_SETTINGS: 'KID innstillinger',
        FORM_SETTINGS: 'Blankettoppsett',
        ELECTRONIC_INVOICE: 'Elektroniske faktura',
        COLLECTOR: 'Innkreving',
        REPORT: 'Innstillinger for blanketter',
        REPORT_LOGO: 'Logo til blankett',
        REMINDER: 'Purreinnstillinger',
        INTEREST_REMINDER_SETTINGS: 'Innstillinger for renter på purring',
        REMINDER_RULES: 'Purreplan',
        REMINDER_FEES: 'Gebyr på purringer',
        REINVOICE: 'Viderefakturering',
        STANDARD_PRODUCTNUMBER: 'Standard produktnummer',
        REINVOICE_JOURNALING_SUGGESTIONS: 'Konteringsforslag',
        PRODUCT_LINK: 'Produktkobling',

        COMPANY_SETTINGS_INFOTEXT:
            'Registrer detaljer om firma. Orgnr feltet kan stå tomt eller fylles med fødselsnummer om virksomhet er privatperson. Du kan også laste opp firmalogo for rapporter.',
        SALES_INVOICE_INFOTEXT: 'Overordnede innstillinger for salg og konto for postering av øredifferanse.',
        CUSTOMER_INFOTEXT:
            'Standard kredittdager for selskapets kunder. Kan overstyres på kundekort ved behov. Ved å aktivere lagring som potensiell kunde kan kunder lagres som leads.',
        PAYMENT_TERMS_INFOTEXT:
            'Oppsett av forhåndsdefinerte kredittdager. Disse betingelsene vil være alternativene man har for kredittdager ved utfakturering dersom man ønsker å avvike fra standard.',
        DELIVERY_TERMS_INFOTEXT:
            'Oppsett av forhåndsdefinerte leveringsdager. Disse betingelsene vil være alternativene man har for leveringsbetingelser ved salg.',
        REPORT_INFOTEXT: 'Velg hvordan blanketter som sendes til kundene dine skal se ut.',
        LOGO_INFOTEXT: 'Her kan du laste opp logo som brukes på blanketter.',
        ACCOUNTS_INFOTEXT: 'Oppsett av samlekonto for reskontroer.',
        ELECTRONIC_INVOICE_INFOTEXT:
            'Her kan du aktivere EHF og Fakturaprint. Om du ikke har kjøpt tjenestene, vil vi ta deg til markedsplassen først.',
        PERIODE_AND_VAT_INFOTEXT:
            'Registrering av MVA-status for selskapet, MVA-perioder og type næring for MVA-formål. Her sperrer man også henholdsvis bokføring og MVA-føringer i regnskapet.',
        CURRENCY_INFOTEXT: 'Valg av standard valuta for selskapet og hovedbokskonto for valutaposteringer.',
        OPENING_BALANCE_INFOTEXT:
            'Har du et selskap der det er ført transaksjoner i tidligere perioder, bruker du denne veiveiseren for å registrere inngående balanse.',
        OPENING_BALANCE_TEXT: 'Du har ikke registrert inngående balanse',
        SUPPLIER_INVOICE_INFOTEXT: 'Sett opp hvordan du ønsker å jobbe med regninger.',
        ACCOUNT_VAT_TYPE_INFOTEXT:
            'Ved å aktivere standard salgskonto per mvakode vil stystemet foreslå standardkontoen per mvakode i varelinjer på tilbud, ordre og faktura.',
        WRITEOFF_INVOICE_INFOTEXT:
            'Ved tapsføring av fordringer kan det være nyttig å motpostere basert på mvakode og konto brukt i det opprinnelige bilaget. Her kan du sette opp hvilke mvakoder og/eller kontoer som skal motføres mot hvilke kontoer',

        OPENING_BALANCE_BUTTON: 'Registrer inngående balanse',
        AGA_SETTINGS1:
            'Firmanavn og organisasjonsnummer fylles automatisk ut dersom man har hentet opplysninger fra Brønnøysundregisteret under Firmaoppsett.',
        AGA_SETTINGS2:
            'Oppsett av sone og beregningsregel for arbeidsgiveravgift. Dersom virksomheten er omfattet av ordning for fribeløp får du oversikt over gjenstående fribeløp her.',
        REMINDER_SETTINGS_INFOTEXT:
            'Overordnede innstillinger for purrede utgående faktura og betingelser for å purre. Antall purringer før inkasso må matche regler i bunnen av bilde.',
        INTEREST_REMINDER_SETTINGS_DESCRIPTION:
            'Her kan du sette opp reglene for når purringer skal renteberegnes. Kun kunder som er valgt vil renteberegnes, du kan legge til og fjerne kunder her. Rentesatsen er satt til Statens Sats, p.t. {rate}%.',
        REMINDER_RULES_INFOTEXT:
            'Purreplanen definerer innkrevingsprosessen for en forfalt faktura. En plan kan bestå av en eller flere purringer, og om ønskelig et inkassovarsel og sending til inkasso. Du kan sette opp egendefinerte tekster, gebyr og antall forfallsdager på purring og inkassovarsel.',
        FETCH_BR_REG_INFOTEXT: 'Du kan også hente opplysninger fra brønnøysundregistrene',
        FETCH_FROM_BR_REG: 'Hent fra brreg',
        REMINDER_FEES_INFOTEXT1:
            'Her kan du sette opp satser for gebyr og velge kunder knyttet til de forskjellige satsene.',
        REMINDER_FEES_INFOTEXT2: 'Makssatsene vedlikeholdes av oss og vil være ihht. lovkravene.',
        REMINDER_FEES_INFOTEXT3:
            'I egendefinert plan kan du velge om purringen skal inkludere gebyr eller ikke. Satsen for gebyr vil hentes fra hvilket gebyr kunden er knyttet til.',

        BANK_INFOTEXT:
            'Definer akseptabelt beløp for differanse for å bokføre, og konto for føringer av gebyr. Huk av "Bokfør kun innbetalinger som matcher" om du ønsker at kun innbetalinger som matcher med salgsfaktura blir bokført, alle andre innbetalinger vil bli ignorert',
        BANK_RULES_INFOTEXT:
            'Bokføringsregler benyttes i bankavstemming for banktransaksjoner som ikke er bokført. Her kan du sette opp nye regler eller redigere eksisterende bokføringsregler.',
        BANK_RULES_INFOTEXT2:
            'Sett hake for “Kjør automatisk” for at systemet skal bokføre og avstemme disse postene i bakgrunnen.',
        BANK_ACCOUNTS_INFOTEXT:
            'Sett opp kontoer for bruk i systemet. Dersom lønnskonto ikke er fylt ut vil lønn bruke driftskonto ved utbetaling av lønn. Du kan sette opp flere kontoer, men valgt konto vil bli brukt som default av systemet.',
        REM_ACCOUNTS_INFOTEXT: 'Mellomkontoer for innbetaling, utbetaling og lønn',
        COMPANY_EMAIL_INFOTEXT:
            'Aktiver epostaddresse for mottak av innkommende faktura. Velg mellom firmanavn og organisasjonsnummer i epostaddressen. E-poster som kommer til denne addressen vil vises direkte i systemets innboks. Ved endring av e-postaddresse, lagre ny e-post med "Endre e-postaddresse"-knappen.',
        SALARY_BOOKING_ACCOUNTS_INFOTEXT:
            'Administrer systemkonti for postering av arbeidsgiveravgift og interimskonto for utbetaling av lønn. Aktiver automatisk postering av skattetrekk for å bokføre trekk til skattetrekkskonto når lønnskjøring bokføres. Huk av for «Utleggstrekk skatt til skattetrekkskonto» for å styre samlet beløp for utleggstrekk på skatt på lønnskjøringer mot skattetrekkskonto i remitteringsfil.',
        SALARY_EMPLOYEE_LEDGER_INFOTEXT:
            'Ansattreskontro må skrus på for hvert selskap det vil brukes på. Dette vil opprette en nummerserie som vil bli tilgjengelig i bruk for utbetalinger av utlegg til den ansatte. Føringer gjort mot ansattreskontro kan enkelt hentes inn og utbetales eller trekkes på en lønnsavregning. Ansattreskontro blir også tilgjengelig i leverandørfaktura/utgift og bilagsføring. Velges det at reskontro skal opprettes for hver enkelt ansatt når det er behov for det, velges "Ved behov". Det er også mulig å velge at systemet automatisk oppretter en reskontro for hver ansatt som opprettes i lønn, da velges "Alle ansatte". Hvis en velger "Ved behov", er det enkelt å opprette en reskontro for en ansatt når en trenger det. Nummerserie opprettes når en lagrer innstillinger.',
        SALARY_OTHER_INFOTEXT:
            'Legg opp intervall for lønnskjøringer på selskapet. Dette angir standard datointervall ved opprettelse av lønnskjøringer. Dersom du aktiverer OTP-eksport må du også fylle ut feltet «Timer per årsverk», som er antall timer som tilsvarer et fullt årsverk i selskapet.',
        SALARY_OTHER_PAYROLLAPPROVAL:
            'Dersom man ønsker godkjenning av lønn før utbetaling kan du aktivere det her. Lønnsavregning må da godkjennes før den kan utbetales.',
        VACATION_PAY_INFOHEADER: 'Beregning av trekk i fastlønn for feriemåneden',
        VACATION_PAY_INFOTEXT1: 'Påvirker kun ansatte med fastlønn, som for eksempel månedslønn.',
        VACATION_PAY_IGNORE_BASEPAY: 'Ignorer grunnbeløp',
        VACATION_PAY_INFOTEXT2:
            'Dette vil kun påvirke ansatte over 60 år. Sett hake dersom du vil at systemet skal beregne tilleggssats på hele feriepengegrunnlaget, og ikke bare opp til 6G slik lovens minstekrav tilsier.',
        VACATION_PAY_BASE_INFOHEADER: 'Registrer feriepengegrunnlag',
        VACATION_PAY_BASE_INFOTEXT1:
            'Ønsker du å registrere opptjent feriepengegrunnlag fra et annet system, korrigere feriepengegrunnlag for en ansatt eller endre sats per ansatt kan du gjøre dette her. ',
        VACATION_PAY_BASE_INFOTEXT2:
            'Dersom du endrer feriepengesats per ansatt vil det overstyre standard feriepengesats.',
        CORPORATE_TAX_INFOTEXT: 'Kryss av og fyll inn hvis det skal beregnes og betales finansskatt av lønn.',
        FEE_INFOTEXT:
            'Skatte- og avgiftsregler er en gruppe skatteregler for spesielle organisasjoner.  Aktiveres en skatteregel vil den bli tilgjengelig for dette selskapet som valg under a-meldingsinformasjon på lønnsarten. Et selskap kan aktivere flere regler, men en lønnsart kan bare kobles mot en skatte- og avgiftsregel.',
        PERIODISED_PAYROLL_INFOTEXT1: 'Vil du bruke periodisering for lønnsbilag, Kryss av.',
        PERIODISED_PAYROLL_INFOTEXT2:
            'Kontoer viser standard valgte kontoer for periodisering, ønskes det egne så kan du endre til andre konti.',
        PERIODISED_PAYROLL_INFOTEXT3:
            'Periodisering skjer ved bokføring av lønnsbilag. Det kan styres fra bilag til bilag om det ønskes periodisering.',
        STANDARD_PRODUCTNUMBER_INFOTEXT:
            'For at viderefakturering skal fungere må alle feltene fylles ut med standard verdier. Disse verdiene vil brukes dersom verdi på leverandørfaktura ikke kjennes igjen.',
        REINVOICE_JOURNALING_SUGGESTIONS_INFOTEXT:
            'Velg standardkontoer som skal foreslås for kontering ved viderefakturering.',
        PRODUCT_LINK_INFOTEXT:
            'Ved håndtering av EHF mottar systemet informasjon om leverandørens produktnummer. For å viderefakturere må systemet ha produktnummer koblet til leverandørproduktene. Du vil ved viderefakturering få opp modal for å koble sammen ukjente leverandørprodukter til ditt produktregister.',
        USER_SETTINGS: 'Profil',
        USER_SETTINGS_INFOTEXT:
            'Her kan du enkelt administrere og sikre kontoen din ved å endre passord, oppdatere e-postadressen og tilpasse påloggingingsmetoden etter ditt ønske.',
        USER_SETTINGS_2FA: 'Tofaktor pålogging',
        USER_SETTINGS_2FA_INFOTEXT:
            'Tofaktor autentisering (2FA) er en sikkerhetsmetode som krever to separate bekreftelser, et passord og en engangskode, for å logge på. Vi anbefaler dette for å styrke beskyttelsen mot uautorisert tilgang.',
        USER_SETTINGS_IDP: 'Koblede kontoer',
        USER_SETTINGS_IDP_INFOTEXT: 'Alternative pålogginger som er koblet til din konto',

        INCOMING_BALANCE: {
            START: {
                REGISTER_HEADER: 'Registrer inngående balanse',
                REGISTER_TEXT:
                    'Ditt selskap har tidligere ført regnskap i et annet system. Her kan du registrere inngående balanse manuelt. Vår veiviser vil vise deg steg for steg hvordan du gjør dette. Har du ingen tidligere transaksjoner ',
                CLICK_HERE: 'klikk her',
                REGISTER_LABEL: 'Legg inn inngående balanse',
                RECOMENDED: 'Anbefalt',
                ADVANCED_REGISTRATION_HEADER: 'Avansert registrering',
                ADVANCED_REGISTRATION_TEXT:
                    'Du kan også bruke SAF-T import eller import av Excel-filer for å registrere din inngående balanse. Disse metodene er mer avanserte og fordrer mer regnskapskompetanse. Det kan likevel være en fordel om du har mye data som skal inn, f.eks om du har mange kunder eller leverandører med åpne poster (Regninger eller kundefaktura som ikke er betalt) eller mange poster i saldobalansen din.',
                ADVANCED_REGISTRATION_SEE_MORE: 'Se avansert',
                'SAF-T': 'SAF-T',
                'SAF-T_TEXT':
                    'Alle regnskapssystemer kan generere en SAF-T fil og denne løsningen er laget for å få alle data på plass på en enkel måte. Ved import fra noen systemer kan imidlertid mer komplekse problemstillinger oppstå. Vi anbefaler derfor denne løsningen bare for brukere med god regnskapsforståelse elle med hjelp av regnskapsfører.',
                IMPORT_LINK_LABEL: 'Gå til importsentralen',
                EXCEL_IMPORT_HEADER: 'Import via Excel',
                EXCEL_IMPORT_TEXT:
                    'Dersom du har rapporter på saldobalanse, åpne kunde- og leverandørposter i excel, kan du via importsentralen vår importere inngående balanse. Før du kan importere åpne poster på kunde og leverandør må du gjorde noen endringer i Excel-filene.',
            },
            MAIN: {
                SAVE_DRAFT_LABEL: 'Lagre kladd',
                UNLOCK_LABEL: 'Lås opp inngående balanse',
                BOOK_LABEL: 'Lagre inngående balanse',
                DRAFT_SAVE_CONFIRM_TOAST: 'Kladd er lagret',
            },
            PREPARATION: {
                INFO_TEXT: 'Her er tre ting du må gjøre før du begynner å registrere inngående balanse i {appName}',
                STEP_1_HEADER: 'Avslutt regnskapet i det gamle regnskapsprogrammet',
                STEP_1_TEXT_1: 'Gå gjennom regnskapet',
                STEP_1_TEXT_2:
                    'Har du regninger eller salgsfaktura som er betalt, men der betalingen ikke er bokført, gjør dette.',
                STEP_1_TEXT_3:
                    'Har du bokført både faktura og betaling, men ikke koblet(lukket) disse postene mot hverandre, må du gjøre dette.',
                STEP_2_HEADER: 'Hent ut rapporter',
                STEP_2_TEXT_1_PART_1: 'Ta ut disse tre rapportene ',
                STEP_2_TEXT_1_STRONG: 'datert dagen før din startdato',
                STEP_2_TEXT_1_PART_2: ' i {appName}:',
                STEP_2_TEXT_2_HEADER: 'Åpne poster - kunder',
                STEP_2_TEXT_2: 'Rapport over hva kunder skylder deg.',
                STEP_2_TEXT_3_HEADER: 'Åpne poster - Leverandør',
                STEP_2_TEXT_3: 'Rapport over hva du skylder dine leverandører.',
                STEP_2_TEXT_4_HEADER: 'Saldobalanse/Balanserapport',
                STEP_2_TEXT_4: 'Oversikt over saldo på alle hovedbokskontoer på valgt dato.',
                STEP_3_HEADER: 'Sjekk at alt balanserer',
                STEP_3_TEXT_1: 'Det er viktig at summen av hele saldobalansen er lik 0.',
                STEP_3_TEXT_2:
                    'Sjekk at totalsummen av åpne poster på kunde stemmer overens med summen på konto 1500 i saldobalansen.',
                STEP_3_TEXT_3:
                    'Sjekk at totalsummen av åpne poster på leverandør stemmer overens med summen på konto 2400 i saldobalansen.',
                STEP_3_TEXT_4:
                    'Er det differanser på noen av disse må du gå tilbake til ditt gamle regnskap og rette opp.',
            },
            DATE: {
                INFO_TEXT:
                    'Det er viktig at det gamle regnskapet er ferdigstilt og avstemt på en gitt dato, og at du velger siste dato i regnskapet fra tidligere system når du legger inn inngående balanse. Hvis du for eksempel starter å bruke {appName} 1. september, henter du ut saldobalanse for 31. august.',
                DATE_LABEL: 'Fra hvilken dato skal du føre regnskap i {appName}?',
                DATE_SUB_TEXT_1: 'Bilaget vil bli bokført {date}',
                DATE_SUB_TEXT_2: 'Datoen vil alltid være siste dag i forrige måned.',
            },
            LIST: {
                INFO_TEXT:
                    'Fyll inn dataene fra saldobalanse-rapporten fra ditt gamle system. Sjekk at differansen på saldoen går i null i rapporten. Gjør den ikke det må du gå tilbake og finne ut hva som er feil og korrigere det i ditt gamle system. Når rapporten er korrekt legger du tallene inn her. Det er viktig at alle tallene du legger inn stemmer overens med de i rapporten, bruk samme fortegn som i rapporten. Når differansen er 0,00 kan du gå videre og registrere åpne poster på kunder og leverandører om du har dette',
                ACCOUNT_LABEL: 'Konto',
                ACCOUNT_NAME_LABEL: 'Kontonavn',
                AMOUNT_LABEL: 'Beløp',
            },
            CUSTOMERS: {
                INFO_TEXT:
                    'Her legger du inn dataene fra rapporten på "Åpne poster kunde". Totalsummen her skal være den samme som på konto 1500 i saldorapporten din. Det er viktig at alle tallene du legger inn stemmer overens med tallene i rapporten, bruk samme fortegn som i rapporten. Først når differansen er 0,00, kan du gå videre',
                CUSTOMER_LABEL: 'Kunde',
                EMPTY_STATE_TEXT: 'I følge saldobalansen har du ingenting å registrere på Åpne poster kunde.',
            },
            SUPPLIERS: {
                INFO_TEXT:
                    'Her legger du inn dataene fra rapporten på "Åpne poster leverandør". Totalsummen her skal være den samme som  på konto 2400 i saldorapporten din. Det er viktig at alle tallene du legger inn stemmer overens med tallene i rapporten, bruk samme fortegn som i rapporten. Først når differansen er 0,00, kan du gå videre og velge å lagre den inngående balansen.',
                SUPPLIER_LABEL: 'Leverandør',
                EMPTY_STATE_TEXT:
                    'I følge saldobalansen har du ingenting å registrere på Åpne poster leverandør. Du kan nå lagre den inngående balansen.',
            },
            BOOKING_MODAL: {
                HEADER: 'Du har nå registrert din inngående balanse!',
                TEXT: 'Inngående balanse er bokført med bilagsnummer',
            },
            ROUTING_MODAL: {
                HEADER: 'Registrere inngående balanse',
                QUESTION: 'Er selskapet nystiftet uten tidligere transaksjoner?',
                YES_TEXT: 'Selskapet er nystiftet uten tidligere transaksjoner',
                NO_TEXT: 'Selskapet har tidligere ført regnskap i et annet system',
            },
            UNLOCK_MODAL: {
                HEADER: 'Lås opp inngående balanse',
                TEXT_PART_1: 'Inngående balanse er bokført med bilagsnummer',
                TEXT_PART_2:
                    'når du velger å låse opp og gjøre endringer vil det opprinnelige bilaget krediteres og det vil bli opprettet et nytt i regnskapet.',
                UNLOCK_BUTTON_LABEL: 'Lås opp',
            },
            TOTALS: {
                TOTAL: 'Totalt: ',
                DIFF: 'Differanse: ',
            },
            NAVIGATION: {
                BACK_TO_START: ' Tilbake til start',
                PREPARATION: '1. Forberedelser',
                DATE: '2. Dato',
                BALANCE: '3. Saldobalansen',
                CUSTOMERS: '4. Åpne poster kunde',
                SUPPLIERS: '5. Åpne poster leverandør',
            },
            INFO: {
                CREATED_MESSAGE: 'Inngående balanse opprettet',
                JOURNAL_ENTRY_NUMBER_LABEL: 'Bilagsnummer',
            },
            COMMON: {
                TITLE: 'Inngående balanse etablert firma',
                NEW_LINE_LABEL: '+ Legg til ny linje',
                FROM_BALANCE: 'Fra saldobalansen:',
                AMOUNT_LABEL: 'Beløp',
                INVOICE_LABEL: 'Fakturanummer',
            },
        },
    },

    NAVBAR: {
        KPI: 'Nøkkeltall',
        DASHBOARD: 'Dashboard',
        COMPANIES: 'Selskaper',
        APPROVALS: 'Godkjenninger',
        OVERVIEW: 'Oversikt',
        RESULT_BALANCE: 'Resultat og balanse',
        DISTRIBUTION: 'Utsendelse',
        DISTRIBUTION_LIST: 'Utsendingsliste',
        EXTRACT: 'Uttrekk (BETA)',
        REPORTS: 'Rapporter',
        SALES: 'Salg',
        BATCH_INVOICE: 'Samlefakturering',
        INVOICE: 'Faktura',
        RECURRING_INVOICE: 'Repeterende faktura',
        ORDER: 'Ordre',
        QUOTE: 'Tilbud',
        REMINDER: 'Purring',
        CUSTOMER: 'Kunder',
        PRODUCTS: 'Produkter',
        PRODUCT_GROUP: 'Produktgrupper',
        SELLERS: 'Selgere',
        CURRENCY: 'Valutakurser',
        COSTALLOCATION: 'Fordelingsnøkler',
        ACCOUNTING: 'Regnskap',
        ACCOUNTING_2: 'Regnskapsføring',
        JOURNALENTRY: 'Bilagsføring',
        SUPPLIER_INVOICE: 'Leverandørfaktura',
        BUDGET: 'Budsjett',
        OPEN_POST: 'Åpne poster',
        CUSTOMER_LEDGER: 'Kundereskontro',
        VAT_MESSAGE: 'MVA-melding',
        VAT_MESSAGEV2: 'MVA-melding v2',
        ANNUAL_SETTLEMENT: 'Årsavslutning',
        FORHAANDSFASTSETTING: 'Forhåndsfastsetting',
        SUBMISSION: 'Innlevering',
        LINKS: 'Koblinger',
        BALANCE_SEARCH: 'Saldobalanse',
        SEARCH_JOURNALENTRY: 'Søk på bilag',
        SEARCH_ACCOUNT: 'Søk på konto',
        SUPPLIER: 'Leverandør',
        ACCOUNT_PLAN: 'Kontoplan',
        VAT_SETTINGS: 'MVA-innstillinger',
        BANK: 'Bank',
        BANKPRODUCTS: 'Bankprodukter',
        PROCEEDS: 'Innbetalinger',
        PAYMENTS: 'Utbetalinger',
        PAYMENT_BATCH: 'Utbetalingsbunter',
        PAYMENT_BATCH_AUTO: 'Avtalegirobunter',
        BANK_RECONCILIATION: 'Bankavstemming',
        MARKETPLACE: 'Markedsplass',
        SALARY: 'Lønn',
        PAYROLL: 'Lønnsavregning',
        PAYROLLV2: 'Lønnsavregning v2',
        AMELDING: 'A-melding',
        INCOMEREPORTS: 'Inntektsmelding',
        BALANCE: 'Saldo',
        ADDITIONAL_INFORMATION: 'Tilleggsopplysninger',
        ANNUAL_ASSIGNMENT: 'Årsoppgave til inntektsmottaker',
        OTP_EXPORT: 'OTP-eksport',
        VARIABLE_PAYROLLS: 'Variable lønnsposter',
        REGULATIVE: 'Regulativ',
        EMPLOYEES: 'Ansatte',
        WAGETYPE: 'Lønnsarter',
        CATAGORIES: 'Kategorier',
        MOVE_TEMPLATES: 'Trekkmaler',
        HOURS: 'Timer',
        HOUR_REG: 'Timeregistrering',
        BILLING_HOURS: 'Fakturering av timer',
        PERSONS: 'Personer',
        TYPES: 'Timearter',
        ADMIN_DAYSOFF: 'Administrer fridager',
        EMPLOYMENT_TEMPLATE: 'Stillingsmal',
        HOUR_STRING: 'timer',
        TEMPLATES: 'Maler',
        GO_TO_TODAY: 'Gå til idag',
        VIEW: 'Visning',
        ALTINN: 'Altinn',
        SETTINGS: 'Innstillinger',
        COMPANY: 'Firmaoppsett',
        PAYROLL_SETTINGS: 'Lønnsinnstillinger',
        INTEGRATION: 'Integrasjoner',
        INVOICEFLOW: 'Flyt og tildeling',
        USERS: 'Brukere',
        USER_SETTINGS: 'Min bruker',
        TEAMS: 'Teams',
        NUMBERSERIES: 'Nummerserier',
        TERMS: 'Betingelser',
        RULES: 'Godkjenningsregler',
        IMPORT_CENTRAL: 'Importsentral',
        JOBS: 'Jobber',
        MODELS: 'Modeller',
        ROLES: 'Roller',
        GDPR: 'GDPR',
        ADMIN: 'Admin',
        DIMENSION: 'Dimensjoner',
        PROJECT: 'Prosjekt',
        PROJECT_BETA: 'Prosjekt [BETA]',
        DEPARTMENT: 'Avdeling',
        DIMENSION_SETTINGS: 'Dimensjoner',
        PAYMENT_AND_DELIVERY: 'Betalings- og leveringsbetingelser',
        SHOW_ALL_NAVBAR: 'Vis alle menyvalg',
        MODULES: 'Moduler',
        WEBINAR: 'Webinar',
        HOME: 'Hjem',
        REGISTER: 'Register',
        OTHER: 'Annet',
        NUMBER_AND_REPORTS: 'Tall og rapporter',
        EXPENSE: 'Utgift',
        INBOX: 'Innboks',
        PURCHASES: 'Produktkjøp',
        AGENCIES: 'Regnskapsbyråer',
        BANK_SETTINGS: 'Bankinnstillinger',
        ASSETS: 'Eiendeler',
        OPENING_BALANCE: 'Inngående balanse nyetablert firma',
        PRICE_DEALS: 'Prisavtaler',
        FILEIMPORT: 'Filimport',
        PENSION: 'Pensjon',
        FINANCING: 'Finansiering',
        INSURANCE: 'Forsikring',
    },

    COMMON: {
        OK: 'Ok',
        CANCEL: 'Avbryt',
        SAVE: 'Lagre',
        CLOSE: 'Lukk',
        NEXT: 'Neste',
        BACK: 'Tilbake',
        YES: 'Ja',
        NO: 'Nei',
        CONTINUE: 'Fortsett',
        CONTACT: 'Kontaktinformasjon',
        GLN: 'GLN (Global Location Number) er et lokasjonsnummer som brukes til entydig å identifisere fysiske lokasjoner, juridiske enheter, eller ulike roller i en handelstransaksjon.',
        NO_SUMMARY_DATA: 'Ingen data å vise i sammendraget',
    },

    DASHBOARD: {
        RECENT_PAYROLL_RUNS: {
            HEADER: 'Siste lønnsavregninger',
            EMPTY: 'Det ser ikke ut som du har noen lønnsavregninger',
            PAYROLL_COLUMN: 'Lønnsavregning',
            STATUS_COLUMN: 'Status',
            PAY_DATE_COLUMN: 'Utbetalingsdato',
        },
        RECENT_EMPLOYEES: {
            HEADER: 'Siste ansatte',
            EMPTY: 'Det ser ikke ut som du har noen ansatte',
            EMPLOYEE_COLUMN: 'Ansatt',
            EMAIL_COLUMN: 'E-post',
            BIRTH_DATE_COLUMN: 'Fødselsdato',
            SUB_ENTITY_COLUMN: 'Virksomhet',
        },
        ACTIVE_EMPLOYEES: {
            HEADER: 'Aktive ansatte',
            EMPTY_MESSAGE: 'Du har ingen ansatte. Registrer din første ansatte i dag!',
            EMPTY_STATE_BUTTON: 'Registrer ansatte',
            HIRED_THIS_YEAR: 'Ansatt i år',
            ENDDATE_THIS_YEAR: 'Sluttdato i år',
            FULL_TIME_EQUIVALENTS: 'Totalt antall årsverk',
        },
        SALARY_SHORTCUTS: {
            HEADER: 'Snarveier',
            EMPLOYEE_TITLE: 'Ansatte',
            WAGE_TYPE_TITLE: 'Lønnsarter',
            PAYROLL_RUN_TITLE: 'Lønnsavregning',
            A_MELDING_TITLE: 'A-melding',
            TICKER_TITLE: 'Oversikt',
            REPORT_TITLE: 'Rapporter',
        },
    },

    RGB: {
        // COMMON RGB TEXTS
        SEND_TO_PAYMENTLIST: 'Legg til betalingsliste',
        DISABLED_NO_AUTOBANK: 'Du må ha aktiv autobankavtale for å sende betaling rett til bank',

        // INVOICE RGB TEXTS
        SEND_INVOICE_TO_BANK: 'Send regning til banken',
        SEND_INVOICE_TO_BANK_INFOTEXT: 'Betaling vil bli sendt direkte til banken',
        SEND_INVOICE_TO_BANK_TWOFACTOR_HEADER: 'Betal og godkjenn med BankID',
        SEND_INVOICE_TO_BANK_TWOFACTOR_INFOTEXT:
            'Betalingen vil bli sendt til banken hvor den vil bli betalt på forfallsdato',
        SEND_INVOICE_TO_PAYMENTLIST_INFOTEXT:
            'Betalingen vil bli lagt til betalingslisten hvor du kan betale den senere eller betale flere samtidig.',

        //PAYROLL RGB TEXTS
        SEND_PAYROLL_TO_BANK: 'Send til banken nå',
        SEND_PAYROLL_TO_BANK_INFOTEXT: '{id} sendes til banken.',
        SEND_PAYROLL_TO_BANK_TWOFACTOR_HEADER: 'Send {id} til bank og godkjenn med BankID',
        SEND_PAYROLL_TO_BANK_TWOFACTOR_INFOTEXT:
            '{id} vil bli sendt til banken hvor den vil bli betalt på forfallsdato',
        SEND_PAYROLL_TO_PAYMENTLIST_INFOTEXT:
            '{id} vil bli lagt til betalingslisten hvor du kan betale den senere eller kjøre flere betalinger samtidig.',

        //TRAVEL RGB TEXTS
        SEND_TRAVEL_TO_BANK: 'Send til banken nå',
        SEND_TRAVEL_TO_BANK_INFOTEXT: '{id} sendes til banken.',
        SEND_TRAVEL_TO_BANK_TWOFACTOR_HEADER: 'Send utlegg til bank og godkjenn med BankID',
        SEND_TRAVEL_TO_BANK_TWOFACTOR_INFOTEXT:
            'Utlegg vil bli sendt til banken hvor den vil bli betalt på forfallsdato',
        SEND_TRAVEL_TO_PAYMENTLIST_INFOTEXT:
            'Utlegg vil bli lagt til betalingslisten hvor du kan betale den senere eller kjøre flere betalinger samtidig.',

        // AGA RGB TEXTS
        SEND_AGA_TO_BANK: 'Send betalinger direkte til bank',
        SEND_AGA_TO_BANK_INFOTEXT: 'Betaling for valgte felt over vil bli sendt til banken.',
        SEND_AGA_TO_BANK_TWOFACTOR_HEADER: 'Send betalinger til bank og godkjenn med BankID',
        SEND_AGA_TO_BANK_TWOFACTOR_INFOTEXT:
            'Betaling for valgte felt over vil bli sendt til banken, og vil bli betalt ved forfallsdato',
        SEND_AGA_TO_PAYMENTLIST_INFOTEXT:
            'Betaling for valgte felt over vil bli lagt til betalingslisten hvor du kan betale den senere eller kjøre flere betalinger samtidig.',

        // SALARY BALANCE ADVANCE TEXTS
        SEND_ADVANCE_TO_BANK: 'Send forskudd til banken nå',
        SEND_ADVANCE_TO_BANK_INFOTEXT: 'Forskuddet vil bli sendt til banken.',
        SEND_ADVANCE_TO_BANK_TWOFACTOR_HEADER: 'Send forskudd til bank og godkjenn med BankID',
        SEND_ADVANCE_TO_BANK_TWOFACTOR_INFOTEXT:
            'Forskuddet vil bli sendt til banken hvor den vil bli betalt på forfallsdato',
        SEND_ADVANCE_TO_PAYMENTLIST_INFOTEXT:
            'Forskuddet vil bli lagt til betalingslisten hvor du kan betale den senere eller kjøre flere betalinger samtidig.',

        // MVA RGB TEXTS
        SEND_MVA_TO_BANK: 'Send betalinger direkte til bank',
        SEND_MVA_TO_BANK_INFOTEXT: 'Betaling for valgte felt over vil bli sendt til banken.',
        SEND_MVA_TO_BANK_TWOFACTOR_HEADER: 'Send betalinger til bank og godkjenn med BankID',
        SEND_MVA_TO_BANK_TWOFACTOR_INFOTEXT:
            'Betaling for valgte felt over vil bli sendt til banken, og vil bli betalt ved forfallsdato',
        SEND_MVA_TO_PAYMENTLIST_INFOTEXT:
            'Betaling for valgte felt over vil bli lagt til betalingslisten hvor du kan betale den senere eller kjøre flere betalinger samtidig.',
    },

    ONBOARDING: {
        BANKID_STEP1_SUB_HEADING: 'Legg inn navn eller organisasjonsnummer på din bedrift',
        BANKID_STEP1_MAIN_BTN_TEXT: 'Registrer bedrift',
        BANKID_STEP2_HEADING: 'Selskapsinformasjon',
        BANKID_STEP2_SUB_HEADING:
            'Her er selskaps- og kontaktinformasjon vi har registrert på dette kundeforholdet. Kontroller at alt stemmer før du aktiverer.',
        COMPANY_NAME: 'Firmanavn',
        ORG_NUMBER: 'Org nr',
        ADDRESS: 'Adresse',
        POSTAL_CODE: 'Postnummer',
        CITY: 'Poststed',
        CONTACT_PERSON: 'Navn',
        EMAIL: 'E-post',
        CONTACT_PHONE: 'Mobilnummer',
    },

    MARKET_PLACE: {
        ACCOUNTING_AGENCIES_HEADING: 'Regnskapsbyråer',
        ACCOUNTING_AGENCIES_DESCRIPTION: ' ',
    },
};
