import { ElsaCompanySettingsDto, ElsaCustomerPartial } from '@app/models';
import { BankAccount } from '@uni-entities';

export enum ONBOARDING_STEPS {
    EXISTING_CUSTOMER, // user is admin on an existing customer, need to store if they select yes or no
    // separate step for selecting existing contract? we already have the data we need in the step above
    SELECT_CONTRACTTYPE,
    SELECT_COMPANY, // select end user agreement (bank), or choose to manually search brreg
    COMPANY_INFORMATION, // email flow
    CONTRACT_ACTIVATION, // email flow
    TAX_TYPE,
    BANK_SERVICES,
    EXISTING_RECONCILIATION, // "Har det blitt ført regnskap for {{companyName}} tidligere? Ja/Nei" (SB1 specific question, I think)
    DEFAULT_BANK_ACCOUNT,
    BANK_ACCOUNTS,
    CONFIRM_AND_CREATE, // should 'creating company'/busy state be it's own step?
    COMPANY_CREATED,
    VALIDATION_ERROR,
}

export interface OnboardingStep {
    stepType: ONBOARDING_STEPS;
    nextStep?: () => ONBOARDING_STEPS | undefined;
    previousStep?: () => ONBOARDING_STEPS | undefined;
    goNextLabel?: string;
    hideGoNextButton?: boolean;
    goBackLabel?: string;
    hideGoBackButton?: boolean;
    isValid?: boolean; // each component(step) decides when it is valid
}

export enum TaxMandatoryType {
    NotTaxMandatory = 1,
    FutureTaxMandatory = 2,
    TaxMandatory = 3,
}

export enum ApprovalLocation {
    InApp = 1,
    InBank = 2,
}

export interface OnboardingBankServices {
    // NB!! Only TRUE or NULL, we need to mutate this object before sending it.
    // The endpoint has changed since the SB1 branch, false is no longer a valid value for these booleans (during onboarding)
    HasRecievables?: boolean; // not in use for eika, set it to null
    HasPayments?: boolean;
    HasPreApprovedPayment?: boolean;
    HasStatements?: boolean;
    BankStatementStartDate?: Date;
}

export interface OnboardingState {
    Customer: ElsaCustomerPartial;
    CompanySettings?: ElsaCompanySettingsDto;
    BankServices?: OnboardingBankServices;
    Extras?: any;
    DemoCompanyName?: string;
}
